const user_scopes = {
    descriptions: {
        custom_data: 'Twoje dane niestandardowe',
        email: 'Twój adres e-mail',
        phone: 'Twój numer telefonu',
        profile: 'Twoje imię, nazwa użytkownika, awatar i inne informacje osobiste',
        roles: 'Twoje role',
        identities: 'Twoje powiązane tożsamości społecznościowe',
        'urn:logto:scope:organizations': 'Twoje informacje o organizacji',
        'urn:logto:scope:organization_roles': 'Twoje role w organizacji',
        address: 'Twój adres',
    },
};
export default Object.freeze(user_scopes);
